
import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, Badge,Container, InputGroup, Accordion} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {  useState, useEffect} from 'react';
import { Spinning } from './loading';
import { URL } from '../common/constant';
import { FileUploadField } from './fileUploadComponent';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelection } from './categoryComponent';
const axios = require('axios').default;


export const ReleasePermanentPowerSupply = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: 0,
        thram: '',
        power_clearance: '',
        license: '',
        terms:false,
        terms2:false,
        yes_no:'',
        internal_wiring: false,
        earthing: false,
        service_cable: false,
        category:'',
        
    });
    const history = useHistory();
    const [hide_show_file, setHideShowFile] = useState('d-none');
    const [hide_show_pc, setHideShowPC] = useState('d-none');
    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1,'Division  is required').required('This field is required'),
        thram:yup.string().required('This field is required'),
        license: yup.string(),
        yes_no:yup.string().required('Please select one'),
        category:yup.string().required('Please select one'),
        power_clearance: yup.number().required('This field is required'),
        terms: yup.bool().required().oneOf([true], 'This Terms must be accepted'),
        terms2: yup.bool().required().oneOf([true], 'This Terms must be accepted'),
        internal_wiring: yup.bool().required().oneOf([true], 'This checkbox must be ticked'),
        earthing: yup.bool().required().oneOf([true], 'This checkbox must be ticked'),
        service_cable: yup.bool().required().oneOf([true], 'This checkbox must be ticked'),
    });
    const [old_power_clearance_file, setOldPowerClearanceFile] = useState({file:'',error:null});
    const [electrification_certificate, setElectrificationCertificate] = useState({file:'',error:null});

    const handleFormSubmit =(values) =>{
        
        if(hide_show_file === 'd-block'){
            if(!old_power_clearance_file.file){
                setOldPowerClearanceFile({...old_power_clearance_file, error:'Old power clearance copy is required'})
                return false;
            }
        }

        setLoading(true);

        if(values.yes_no === 'no'){
            finalSubmit(values,'no');
        }
        else{
            axios.get(`${URL}/tickets/${values.power_clearance}/`,
                { headers: { "Authorization": localStorage.getItem('token'), }}
            )
            .then(function (response) {
                
                if(response.data.service === "Power Clearance"){
                    if(response.data.status === "Pending"){
                        setShowModal(true);
                        setModalContent(`Sorry, the Power Clearance Application ID ${values.power_clearance} has not been approved yet. Try again next time`);
                        setLoading(false);
                    }

                    else if(response.data.status === "Rejected"){
                        setShowModal(true);
                        setModalContent(`Sorry, the Power Clearance Application ID ${values.power_clearance} has been Rejected`);
                        setLoading(false);
                    }

                    else{
                        finalSubmit(values,response);
                    }
                }
                else{
                    setShowModal(true);
                    setModalContent(`Sorry, power clearance Application ID ${values.power_clearance} is not valid`);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setShowModal(true);
                if(error.response){
                    if(error.response.status === 404){
                        setModalContent(`Sorry  power clearance Application ID ${values.power_clearance} is not valid`);
                    }
                    else{
                        setModalContent(JSON.stringify(error.response));
                    }
                }
                
                else
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    }

    const finalSubmit = (values, response) => {
        let formData = new FormData();
        formData.append('location', values.location);
        formData.append('division', values.division);
        formData.append('thram', values.thram);
        formData.append('category', values.category);
        
        if(values.yes_no === 'no'){
            formData.append('power_clearance', '');
            formData.append('old_power_clearance', old_power_clearance_file.file);
        }
            
        else
            formData.append('power_clearance', response.data.service_id);

        formData.append('license', values.license);
        formData.append('request_type', 'Permanent');
        formData.append('certificate_copy',electrification_certificate.file );
        
        
        axios.post(`${URL}/services/releases/`,formData,{
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });

    }

    const hideShowROWField = (status, setFieldValue) =>{
        if (status){
            setHideShowFile('d-none');
            setHideShowPC('d-block mb-3');
            setFieldValue('power_clearance', '');
            
        }
        else{
            setHideShowPC('d-none mb-3');
            setHideShowFile('d-block');
            setFieldValue('power_clearance', 1);
            
        }
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    useEffect(() => {
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/releases/${params.get('service_id')}/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            setFormikValues(response.data);
            setHideShowPC();
            alert('whats up');

        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });

        }
    },[]);

    

    return (
        <Container>
            
            <GoBackButton />
            <h3 className="text-center mt-2">
                {(params.get('ticket'))?<>Application ID <Badge bg="info" className="me-2">{params.get('ticket')}</Badge></>:null}   
                Permanent power supply
            </h3>
            <hr></hr>
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where supply is required" >
                                <Form.Control type="text" 
                                    placeholder="Name of location" 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Thram/Plot No. of the premise" >
                                <Form.Control type="text" 
                                    placeholder="Thram/Plot No. of the premise" 
                                    name="thram"
                                    value={values.thram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.thram && !!errors.thram}
                                    isValid={ touched.thram && !errors.thram}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.thram}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            <FloatingLabel label="License No." >
                                <Form.Control 
                                    type="text" 
                                    placeholder="License No." 
                                    name="license"
                                    value={values.license}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.license && !!errors.license}
                                    isValid={ touched.license && !errors.license}
                                    disabled={is_submitted}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">{errors.license}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className='mb-3'>
                        <CategorySelection 
                            values = {values}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                            label={'Area'}
                        />
                    </Row>

                    {
                        (params.get('service_id') !== null)?
                        null:
                        <>
                           
                            <Row className='mt-4 ms-1 mb-4'>
                                Do you have the previously approved power clearance application ID issued from this system?
                            
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="yes_no"
                                    type="radio"
                                    id='yes'
                                    onClick={()=>hideShowROWField(true,setFieldValue)}
                                    
                                    value='yes'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="yes_no"
                                    type="radio"
                                    id='no'
                                    onClick={()=>hideShowROWField(false,setFieldValue)}
                                    value='no'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                
                            </Row>
                            <Row className={hide_show_file}> 
                                <FileUploadField 
                                    name="old_power_clearance" 
                                    label="Old Power Clearance Copy"
                                    file={old_power_clearance_file}
                                    setFile = {setOldPowerClearanceFile}
                                />
                            </Row>
                            
                        </>
                    }

                    <Row  className={hide_show_pc}>
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Power Clearance Application ID" >
                                <Form.Control type="number" 
                                    placeholder="Power Clearance Application ID" 
                                    name="power_clearance"
                                    value={values.power_clearance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.power_clearance && !!errors.power_clearance}
                                    isValid={ touched.power_clearance && !errors.power_clearance}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.power_clearance}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Text className="text-muted">
                            Please use the previously approved power clearance application ID for the service available under view application of this platform. 
                            </Form.Text>
                        </Form.Group>
                    </Row>

                    {/* <Accordion defaultActiveKey="0" className='mb-4'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Technical Details</Accordion.Header>
                            <Accordion.Body className='bg-light'>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6}  className="mb-3 mb-md-0">
                                        <FloatingLabel label="Applied Load" >
                                            <Form.Control type="number" 
                                                placeholder="Applied Load" 
                                                name="applied_load"
                                                //value={values.thram}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                //isInvalid={ touched.thram && !!errors.thram}
                                                //isValid={ touched.thram && !errors.thram}
                                                disabled={is_submitted}
                                            />
                                            <Form.Text className="text-muted">
                                                As per installation design and demand factor
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6}>
                                        <FloatingLabel label="Power Factor" >
                                            <Form.Control 
                                                type="number" 
                                                placeholder="Power Factor" 
                                                name="power_factor"
                                                //value={values.license}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // isInvalid={ touched.license && !!errors.license}
                                                //isValid={ touched.license && !errors.license}
                                                disabled={is_submitted}
                                            />
                                        </FloatingLabel>
                                        <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6}  className="mb-3 mb-md-0">
                                            <InputGroup >
                                                <FloatingLabel label="Motor Capacity" >
                                                    <Form.Control type="number" 
                                                        name="motor_capacity"
                                                        placeholder="Motor Capacity" 
                                                        //name="power_required"
                                                        //value={values.power_required}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        //isInvalid={ touched.power_required && !!errors.power_required}
                                                        //isValid={ touched.power_required && !errors.power_required}
                                                        disabled={is_submitted}
                                                    />
                                                </FloatingLabel>
                                                <InputGroup.Text>HP</InputGroup.Text>
                                                <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                                            </InputGroup>
                                                
                                            <Form.Text className="text-muted">
                                                Only if any motors are to be used. In Horse Power (HP) unit.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group as={Col} md={6}>
                                            <InputGroup >
                                                <FloatingLabel label="Welding machine capacity" >
                                                    <Form.Control type="number"
                                                        placeholder="Welding machine capacity" 
                                                        name="welding_machine"
                                                        //name="power_required"
                                                        //value={values.power_required}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        //isInvalid={ touched.power_required && !!errors.power_required}
                                                        //isValid={ touched.power_required && !errors.power_required}
                                                        disabled={is_submitted}
                                                    />
                                                </FloatingLabel>
                                                <InputGroup.Text>kW</InputGroup.Text>
                                                <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                                            </InputGroup>
                                                
                                            <Form.Text className="text-muted">
                                                If any welding machines are to be used
                                            </Form.Text>
                                        </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6}  className="mb-3 mb-md-0">
                                        <InputGroup >
                                            <FloatingLabel label="Capacitor ratings" >
                                                <Form.Control type="number"
                                                    placeholder="Capacitor ratings" 
                                                    name="capacitor_ratings"
                                                    //value={values.power_required}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    //isInvalid={ touched.power_required && !!errors.power_required}
                                                    //isValid={ touched.power_required && !errors.power_required}
                                                    disabled={is_submitted}
                                                />
                                            </FloatingLabel>
                                            <InputGroup.Text>kW</InputGroup.Text>
                                            <Form.Control.Feedback type="invalid">{}</Form.Control.Feedback>
                                        </InputGroup>
                                        <Form.Text className="text-muted">
                                            If any capacitor banks are to be used ( Between 1 and 1000)
                                        </Form.Text>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} >
                                        <FloatingLabel label="Special Equipment">
                                            <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100px' }}
                                            placeholder="Welding machine capacity" 
                                            name="special_equipment"
                                            //value={values.power_required}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            //isInvalid={ touched.power_required && !!errors.power_required}
                                            //isValid={ touched.power_required && !errors.power_required}
                                            disabled={is_submitted}
                                        />
                                        </FloatingLabel>
                                        <Form.Text className="text-muted">
                                            Any other equipments with special characteristic
                                        </Form.Text>
                                    </Form.Group>
                                </Row>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> */}

                    

                    
                {
                    (params.get('service_id') !== null)?null:
                
                <>
                    <Row> 
                        <FileUploadField 
                            name="electrification_certificate" 
                            label="Electrician Certificate"
                            file={electrification_certificate}
                            setFile = {setElectrificationCertificate}
                        />
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                        <Form.Check
                            required
                            name="internal_wiring"
                            label="Internal wiring is completed "
                            onChange={handleChange}
                            isInvalid={!!errors.internal_wiring}
                            feedback={errors.internal_wiring}
                            feedbackType="invalid"
                            id="internal_wiring"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.internal_wiring}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}  >
                        <Form.Check
                            required
                            name="earthing"
                            label="Earthing is installed "
                            onChange={handleChange}
                            isInvalid={!!errors.earthing}
                            feedback={errors.earthing}
                            feedbackType="invalid"
                            id="earthing"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.earthing}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                        <Form.Check
                            required
                            name="service_cable"
                            label="Service cable is laid "
                            onChange={handleChange}
                            isInvalid={!!errors.service_cable}
                            feedback={errors.service_cable}
                            feedbackType="invalid"
                            id="service_cable"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.service_cable}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    
                    
                    <Row className="mb-3">
                        <Form.Group as={Col}  className="mb-2 ">
                        <Form.Check
                            required
                            name="terms"
                            label="I/We assure that all the information filled in above by me/us are true and that I/we am/are liable
                            for any action by the Company if any of the information provided to the Company or filled in here
                            are incorrect. Further, I/We assure that I/we do not owe any amount as outstanding to the
                            Company and have not been party to any defaulter"
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="terms Feedback"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}  className="mb-2 ">
                        <Form.Check
                            required
                            name="terms2"
                            label="I/We also hereby agree to be bound by the Company's Terms and Conditions of Supply of Electricity
                            to Low Voltage and Low Voltage Bulk Customers, Schedule of Tariffs and Miscellaneous Charges
                            and any changes that is incorporated from time to time (available in the Company’s website:
                            www.bpc.bt) "
                            onChange={handleChange}
                            isInvalid={!!errors.terms2}
                            feedback={errors.terms2}
                            feedbackType="invalid"
                            id="terms Feedback2"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.terms2}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    
                    <Form.Group as={Col}   className="mt-2">
                        <Button type="submit" disabled={is_loading || is_submitted}>
                            {is_loading ? <Spinning />: null}
                            Submit 
                        </Button>
                    </Form.Group>
                </>
                }
            </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    )
}