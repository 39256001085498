
import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, Badge, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {  useState, useEffect} from 'react';
import { Spinning } from './loading';
import { FileUploadField } from './fileUploadComponent';
import { URL } from '../common/constant';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelection } from './categoryComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
const axios = require('axios').default;


export const ReleaseTemporaryPowerSupply = (props) =>{
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: 0,
        thram: '',
        reason: '',
        power_clearance: '',
        license: '',
        site_ready_checbox: false,
        yes_no:'',
        terms:false,
        terms2:false,
        request_type:'',
        category: ''
    });
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('')
    const history = useHistory();
    const [hide_show_file, setHideShowFile] = useState('d-none');
    const [hide_show_pc, setHideShowPC] = useState('d-none');
    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1,'Division  is required').required(),
        thram:yup.string().required('This field is required'),
        reason: yup.string().required('This field is required'),
        license: yup.string(),
        yes_no:yup.string().required('Please select one'),
        category:yup.string().required('Please select one'),
        power_clearance: yup.number().required('This field is required'),
        terms: yup.bool().required().oneOf([true], 'This Terms must be accepted'),
        terms2: yup.bool().required().oneOf([true], 'This Terms must be accepted'),
        site_ready_checbox: yup.bool().required().oneOf([true], 'This checkbox must be ticked'),
        
    });
    const [old_power_clearance_file, setOldPowerClearanceFile] = useState({file:'',error:null});
    const [cid_copy, setCIDCopyFile] = useState({file:'',error:null});
    const [approval_copy, setApprovalCopyFIle] = useState({file:'',error:null});
    
	const handleFormSubmit =(values) =>{
        if(hide_show_file === 'd-block'){
            if(!old_power_clearance_file.file){
                setOldPowerClearanceFile({...old_power_clearance_file, error:'Old power clearance copy is required'})
                return false;
            }
        }
        

        if(!cid_copy.file)
        setCIDCopyFile({...cid_copy, error:'Copy of CID of the owner of the premises, if power clearance is not obtained '});

        else if(!approval_copy.file)
            setApprovalCopyFIle({...approval_copy, error:'Copy of approval for the proposed activity/construction'});
        
        else{
            if(values.yes_no === 'no'){
                finalSubmit(values,'no');
            }
            else{
                setLoading(true);

                axios.get(`${URL}/tickets/${values.power_clearance}/`,
                    { headers: { "Authorization": localStorage.getItem('token'), }}
                )
                .then(function (response) {
                    if(response.data.service === "Power Clearance"){
                        if(response.data.status === "Pending"){
                            setShowModal(true);
                            setModalContent(`Sorry the Power Clearance Application ID ${values.power_clearance} has not been approved yet, Try again next time`);
                            setLoading(false);
                        }

                        else if(response.data.status === "Rejected"){
                            setModalContent(`Sorry the Power Clearance Application ID ${values.power_clearance} has been Rejected`);
                            setShowModal(true);
                            setLoading(false);
                        }

                        else{
                            
                            finalSubmit(values,response);
                        }
                    }
                    else{
                        setModalContent(`Sorry  power clearance number Application ID ${values.power_clearance} is not valid`);
                        setShowModal(true)
                        setLoading(false);
                    }
                    
                
                })
                .catch(function (error) {
                    setLoading(false);
                    setShowModal(true);
                    if(error.response){
                        if(error.response.status === 404){
                            setModalContent(`Sorry  power clearance Application ID ${values.power_clearance} is not valid`);
                            
                            
                        }
                    }

                    
                    else{
                        setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
                    }
                    
                    
                });
            }
        }
    }

    const finalSubmit = (values, response) => {
        setLoading(true);
        let formData = new FormData();
        formData.append('location', values.location);
        formData.append('reason', values.reason);
        formData.append('division', values.division);
        formData.append('thram', values.thram);
        formData.append('category', values.category);
        
        if(values.yes_no === 'no'){
            formData.append('power_clearance', '');
            formData.append('old_power_clearance', old_power_clearance_file.file);
        }
            
        else
            formData.append('power_clearance', response.data.service_id);

        formData.append('license', values.license);
        formData.append('cid_copy', cid_copy.file);
        formData.append('approval_copy', approval_copy.file);
        formData.append('request_type', 'Temporary');
        
        axios.post(`${URL}/services/releases/`,formData,{
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                if(error.response.status === 400){
                    setModalContent(JSON.stringify(error.response.data));
                }
                else
                    setModalContent(JSON.stringify(error.response.data));

            }
            
            else{
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            }
                
        });
    }

    const hideShowROWField = (status, setFieldValue) =>{
        if (status){
            setHideShowFile('d-none');
            setHideShowPC('d-block mb-3');
            setFieldValue('power_clearance', '');
            
        }
        else{
            setHideShowPC('d-none mb-3');
            setHideShowFile('d-block');
            setFieldValue('power_clearance', 1);
            
        }
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    useEffect(() => {
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/releases/${params.get('service_id')}/`,
                {headers: {
                    "Authorization": localStorage.getItem('token'),
                }}
            ).then(function (response) {
                setHideShowPC();
                setFormikValues(response.data);
                getProperPCApplicationID(response.data.power_clearance, response.data);

            }).catch(function (error) {
                setShowModal(true)
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    },[]);

    const getProperPCApplicationID = (power_clearance_pseudo, response_data) =>{
        if(power_clearance_pseudo){
            
            axios.get(`${URL}/services/power-clearances/${power_clearance_pseudo}/`,
                {headers: {
                    "Authorization": localStorage.getItem('token'),
                }}
            ).then(function (response) {
                setFormikValues({...response_data, power_clearance: response.data.id});
            }).catch(function (error) {
                setShowModal(true)
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    }



    return (
        <>
        <Container>
            
            <GoBackButton />
            <h3 className="text-center mt-2">
            {
                (params.get('ticket'))?
                    <>
                        Application ID <Badge bg="info" className="me-2">
                        {params.get('ticket')}</Badge>
                        {formik_values.request_type} power supply
                    </>
                :'Temporary power supply'
            }   
            
            </h3>
            {
                (!params.get('ticket'))?
                    <i className='text-secondary'>
                        <FontAwesomeIcon icon={faInfoCircle} className='me-2 text-secondary' />
                        For construction purposes or short term events/activities.
                    </i>:null
            }
            <hr></hr>
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where supply is required" >
                                <Form.Control type="text" 
                                    placeholder="Name of location" 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Thram/Plot No. of the premise" >
                                <Form.Control type="text" 
                                    placeholder="Thram/Plot No. of the premise" 
                                    name="thram"
                                    value={values.thram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.thram && !!errors.thram}
                                    isValid={ touched.thram && !errors.thram}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.thram}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                            <FloatingLabel label="Temporary Power Required For" >
                            <Form.Select 
                                placeholder="Temporary Power Required For" 
                                name="reason"
                                value={values.reason}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.reason && !!errors.reason}
                                isValid={ touched.reason && !errors.reason}
                                disabled={is_submitted}
                            >       
                                <option value="">Choose one / Reset</option>
                                <option value="Residential">Residential Building</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Others">Others</option>
                                
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2">
                            <FloatingLabel label="License No." >
                                <Form.Control 
                                    type="text" 
                                    placeholder="License No." 
                                    name="license"
                                    value={values.license}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.license && !!errors.license}
                                    isValid={ touched.license && !errors.license}
                                    disabled={is_submitted}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">{errors.license}</Form.Control.Feedback>
                        </Form.Group>
                        <CategorySelection 
                            values = {values}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                            label={'Area'}
                        />

                    </Row>

                

                  

                    {
                        (params.get('service_id') !== null)?
                        null:
                        <>
                            <Row className="mb-3">
                                    <Form.Group as={Col} >
                                    <Form.Check
                                        required
                                        name="site_ready_checbox"
                                        label="Site ready for drawing temporary supply (Service cable is laid & Internal wiring is completed)"
                                        onChange={handleChange}
                                        isInvalid={!!errors.site_ready_checbox}
                                        feedback={errors.site_ready_checbox}
                                        feedbackType="invalid"
                                        id="site_ready_checbox"
                                        disabled={is_submitted}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.site_ready_checbox}</Form.Control.Feedback>
                                    </Form.Group>
                            </Row>
                            

                            <Row className='mt-4 ms-1 mb-4'>
                                Do you have the previously approved power clearance application ID issued from this system?
                            
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="yes_no"
                                    type="radio"
                                    id='yes'
                                    onClick={()=>hideShowROWField(true,setFieldValue)}
                                    
                                    value='yes'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="yes_no"
                                    type="radio"
                                    id='no'
                                    onClick={()=>hideShowROWField(false,setFieldValue)}
                                    value='no'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                
                            </Row>
                        </>
                    }

                    <Row  className={hide_show_pc}>
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Power Clearance Application ID" >
                                <Form.Control type="number" 
                                    placeholder="Power Clearance Application ID" 
                                    name="power_clearance"
                                    value={values.power_clearance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.power_clearance && !!errors.power_clearance}
                                    isValid={ touched.power_clearance && !errors.power_clearance}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.power_clearance}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Text className="text-muted">
                            Please use the previously approved power clearance application ID for the service available under view application of this platform. 
                            </Form.Text>
                        </Form.Group>
                    </Row>

                    
                    {
                        (params.get('service_id') !== null)?null:
                        <>
                            <Row className={hide_show_file}> 
                                <FileUploadField 
                                    name="old_power_clearance" 
                                    label="Old Power Clearance Copy"
                                    file={old_power_clearance_file}
                                    setFile = {setOldPowerClearanceFile}
                                />
                            </Row>

                            

                            <Row>
                                <FileUploadField 
                                    name="cid_copy" 
                                    label="Copy of CID of the owner of the premises, if power clearance is not obtained "
                                    file={cid_copy}
                                    setFile = {setCIDCopyFile}
                                />

                                <FileUploadField 
                                    name="approval_copy" 
                                    label="Copy of approval for the proposed activity/construction"
                                    file={approval_copy}
                                    setFile = {setApprovalCopyFIle}
                                />
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col}  className="mb-2 ">
                                <Form.Check
                                    required
                                    name="terms"
                                    label="I/We assure that all the information filled in above by me/us are true and that I/we am/are liable
                                    for any action by the Company if any of the information provided to the Company or filled in here
                                    are incorrect. Further, I/We assure that I/we do not owe any amount as outstanding to the
                                    Company and have not been party to any defaulter"
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms}
                                    feedback={errors.terms}
                                    feedbackType="invalid"
                                    id="terms Feedback"
                                    disabled={is_submitted}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col}  className="mb-2 ">
                                <Form.Check
                                    required
                                    name="terms2"
                                    label="I/We also hereby agree to be bound by the Company's Terms and Conditions of Supply of Electricity
                                    to Low Voltage and Low Voltage Bulk Customers, Schedule of Tariffs and Miscellaneous Charges
                                    and any changes that is incorporated from time to time (available in the Company’s website:
                                    www.bpc.bt) "
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms2}
                                    feedback={errors.terms2}
                                    feedbackType="invalid"
                                    id="terms Feedback2"
                                    disabled={is_submitted}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.terms2}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            
                            <Form.Group as={Col}   className="mt-2">
                                <Button type="submit" disabled={is_loading || is_submitted}>
                                    {is_loading ? <Spinning />: null}
                                    Submit 
                                </Button>
                            </Form.Group>
                        </>
                    }
            </Form>
                )}
           </Formik>
           
        </Container>
        <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} /></>
    )
}