export const MAX_FILE_SIZE = 1000000;
export const ALLOWED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
export const URL = 'https://osradmin.bpc.bt/api';
export const CONSTRUCTION_TYPE =["Permanent","Temporary","Institution", "Commercial","Industry"];
export const APPLICANT_TYPE =["Tenant","Owner"];

export const SERVICES ={
    "Interim Billing":{id:1,name:"Interim Billing", api:"/interim-billings", component:"/interim-billing"},
    "Information Change":{id:2,name:"Information Change", api:"/information-changes", component:"/change-customer-information"},
    "Power Clearance":{id:3,name:"Power Clearance", api:"/power-clearances", component:"/power-clearance"},
    "Energy Meter":{id:4,name:"Energy Meter", api:"/energy-meters", component:"/energy-meter-upgradation"},
    "Site Clearance":{id:5,name:"Construction Clearance", api:"/site-clearances", component:"/construction-clearance"},
    "Release":{id:5,name:"Release ", api:"/site-clearances", component:"/release-temporary-power-supply"},
    "Connection":{id:6,name:"Connection", api:"/connections", component:"/reconnection-power-supply"},
    "Sanction":{id:7,name:"Sanction of Power", api:"/sanctions", component:"/sanction-power"},
    "Shifting":{id:8,name:"Shifting of Electrical Infrastructure", api:"/shiftings", component:"/shifting-electricity-infra"},
    "Account Closure":{id:9,name:"Account Closure", api:"/account-closures", component:"/account-closure-security-refund"},
};