import {Form} from 'react-bootstrap';
import { useState } from 'react';
import { MAX_FILE_SIZE, ALLOWED_FILE_TYPES } from '../common/constant';

export const  FileUploadField = (props) =>{
    const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState(null);
    
    const fileChangeHandler = (event) => {
        if(event.target.files[0].size >= MAX_FILE_SIZE){
            setFileSizeErrorMessage('Your File is greater than 1 MB');
            props.setFile({...props.file, file:'', error:null});
            event.target.value='';
        }

        else{
            if(!ALLOWED_FILE_TYPES.includes(event.target.files[0].type)){
                setFileSizeErrorMessage('Your File type is not supported. Only pdf, jpeg, png is allowed');
                event.target.value='';
                props.setFile({...props.file, file:'', error:null});
            }
            else{
                props.setFile({...props.file, file:event.target.files[0], error:null});
                setFileSizeErrorMessage();
                setFileSizeErrorMessage();
            }
        }
    };

    const erraseErrorMessage = () => {
        props.setFile({...props.file, file:'', error:null});
        setFileSizeErrorMessage();
        setFileSizeErrorMessage();
    }

    return(
        <Form.Group controlId="formFile" className="mb-4">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control 
                type="file" 
                placeholder={props.label}
                name={props.name}
                onChange={(e)=>(e.target.files[0] === undefined)?erraseErrorMessage():fileChangeHandler(e)}
                isInvalid ={fileSizeErrorMessage  || props.file.error}
                isValid ={props.file.file!==''}
                ref={props.fileRef}
                
            />
            <Form.Text className="text-muted">
                Maximum Attachment Size Allowed is 1 MB
            </Form.Text>
            <Form.Control.Feedback type="invalid">{props.file.error || fileSizeErrorMessage  }</Form.Control.Feedback>
        </Form.Group>
    )
}