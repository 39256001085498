import './App.css';

import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ServiceMenuComponent } from "./components/serviceMenuComponent";
import { useEffect } from 'react';
import { useState } from 'react';
import { ShiftingElectricityInfraForm } from './components/shiftingElectricityInfraComponent';
import { CustomerInputComponent } from './components/customerInputComponent';
import { NavBar} from './components/navBarComponent';
import { ReleaseTemporaryPowerSupply } from './components/releaseTemporaryPowerSupplyComponent';
import { EnergyMeterUpgradation } from './components/energyMeterUpgradationComponent';
import { RelocationEnergyMeter } from './components/relocationOfEnergyMeterComponent';
import { AdditionalEnergyMeterInstallation } from './components/additionalEnergyMeterInstallationComponent';
import { InterimBilling } from './components/interimBillingComponent';
import { ChangeCustomerInformation } from './components/changeCustomerInformationComponent';
import { AccountClosureSecurityRefund } from './components/accountClosureSecurityRefundComponent';
import { TemporaryDisconnectionSupply } from './components/temporaryDisconnectionSupplyComponent';
import { ReconnectionPowerSupply } from './components/reconnectionPowerSupplyComponent';
import { Ticket } from './components/ticketComponent';
import { AddTestingReplacementMeter } from './components/addTestingReplacementMeter';
import { ReleasePermanentPowerSupply } from './components/releasePermanantSupplyComponent';
import { URL } from './common/constant';
import { PowerClearanceFormComponent } from './components/powerClearanceFormComponent';
import { SanctionPower } from './components/sanctionPowerFormComponent';
import { CustomerRegisterComponent } from './components/customerRegistrationForm';
import { ConstructionClearanceFormComponent } from './components/constructionClearanceFormComponent';
import { ErrorModal } from './components/successModal';
const axios = require('axios').default;


function App() {
  const [coco, setCoco] = useState([]);
  const[showModal,setShowModal] = useState(false);
  const[modalContent,setModalContent]= useState('');
  const [dzongkhags, setDzongkhags] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const [customer, setCustomer] = useState({
    checkingIfCustomerIsRegistered: false,
    disabled: false,
    loggedIn:false,
    registeredCustomer:false,
    identification:'',
    mobile:'',
    name:'',
    email:'',
    fixed_line:'',
    village:'',
    gewog:'',
    dungkhag:'',
    dzongkhag:'',
    present_address:'',
    present_dzongkhag:''
  });

  const checkedLoggedIn = (dzongkhags,gewogs) => {
    
    if(localStorage.getItem('token') !== null){
      axios.get(`${URL}/customers/profile/`,
        {headers: {
            "Authorization": localStorage.getItem('token'),
        }}
      )
      .then(function (response) {
        const new_gewog = gewogs.find(gm => gm.name === response.data.permanent_address.gewog);
        const new_permanent_dzongkhag = dzongkhags.find(d => d.name === response.data.permanent_address.dzongkhag);
        const new_present_dzongkhag = dzongkhags.find(d => d.name === response.data.current_address.dzongkhag);
        setCustomer({
          identification:response.data.cid, 
          mobile:response.data.mobile,
          checkingIfCustomerIsRegistered:false,
          loggedIn:true,
          disabled:true,
          registeredCustomer:true,
          name:response.data.name,
          email:response.data.email,
          village:response.data.permanent_address.village,
          gewog:new_gewog.id,
          dungkhag:'',
          fixed_line: response.data.fixed_line,
          dzongkhag:new_permanent_dzongkhag.id,
          present_address:response.data.current_address.address,
          present_dzongkhag:new_present_dzongkhag.id
        });
      })
      .catch(function (error) {
        setShowModal(true);
        if(error.response){
            setModalContent(JSON.stringify(error.response.data));
        }
        
        else
            setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        
      });
    }
  }

  useEffect(() => {
    axios.get(`${URL}/addresses/dzongkhags/`)
    .then(function (response) {
      setDzongkhags(response.data);
      fetchGewogs(response.data);
    })
    .catch(function (error) {
      setShowModal(true);
      if(error.response){
          setModalContent(JSON.stringify(error.response.data));
      }
      
      else
          setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
    });

    axios.get(`${URL}/divisions/`)
    .then(function (response) {
      setDivisions(response.data);
      let puti =[];
      response.data.map(rd => {
        puti.push({ label:rd.name, value: rd.id })
      })

      setCoco(puti);
      console.log(coco);
      
    })
    .catch(function (error) {
      setShowModal(true);
      if(error.response){
          setModalContent(JSON.stringify(error.response.data));
      }
      
      else
          setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
    });
  },[]);

const fetchGewogs = (dzongkhags) =>{
  axios.get(`${URL}/addresses/gewogs/`)
  .then(function (response) {
      checkedLoggedIn(dzongkhags,response.data);

  })
  .catch(function (error) {
    setShowModal(true);
    if(error.response){
        setModalContent(JSON.stringify(error.response.data));
    }
    
    else
        setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
  });
}

  return (
    <>
    <Router>
      <NavBar customer={customer} setCustomer={setCustomer} />
      
      <div className="mb-5">
        <Switch>

          <Route path="/power-clearance">
            {(customer.loggedIn)?
              <PowerClearanceFormComponent divisions={divisions}/>: 
              <Redirect to="/customer-login/power-clearance" /> 
            }
          </Route>

          <Route path="/construction-clearance">
            {(customer.loggedIn)?
              <ConstructionClearanceFormComponent divisions={divisions}/>: 
              <Redirect to="/customer-login/construction-clearance" /> 
            }
          </Route>
          
          <Route path="/sanction-power">
            {(customer.loggedIn)?
              <SanctionPower divisions={divisions} />: 
              <Redirect to="/customer-login/sanction-power" /> 
            }
          </Route>
          
          <Route path="/shifting-electricity-infra">
            {(customer.loggedIn)?
              <ShiftingElectricityInfraForm divisions={divisions}  />: 
              <Redirect to="/customer-login/shifting-electricity-infra" /> 
            }
          </Route>

          <Route path="/release-temporary-power-supply">
            {(customer.loggedIn)?
              <ReleaseTemporaryPowerSupply divisions={divisions} />: 
              <Redirect to="/customer-login/release-temporary-power-supply" /> 
            }
          </Route>

          <Route path="/release-permanent-power-supply">
            {(customer.loggedIn)?
              <ReleasePermanentPowerSupply divisions={divisions} />: 
              <Redirect to="/customer-login/release-permanent-power-supply" /> 
            }
          </Route>

          <Route path="/energy-meter-upgradation">
            {(customer.loggedIn)?
              <EnergyMeterUpgradation divisions={divisions} />: 
              <Redirect to="/customer-login/energy-meter-upgradation" /> 
            }
          </Route>

          <Route path="/energy-meter-relocation">
            {(customer.loggedIn)?
              <RelocationEnergyMeter divisions={divisions} />: 
              <Redirect to="/customer-login/energy-meter-relocation" /> 
            }
          </Route>

          <Route path="/additional-energy-meter-installation">
            {(customer.loggedIn)?
              <AdditionalEnergyMeterInstallation divisions={divisions} />: 
              <Redirect to="/customer-login/additional-energy-meter-installation" /> 
            }
          </Route>

          <Route path="/testing-replacement-meter">
            {(customer.loggedIn)?
              <AddTestingReplacementMeter divisions={divisions} />:
              <Redirect to="/customer-login/testing-replacement-meter" /> 
            }
          </Route>

          <Route path="/interim-billing">
            {(customer.loggedIn)?
              <InterimBilling divisions={divisions} />: 
              <Redirect to="/customer-login/interim-Billing" /> 
            }
          </Route>

          <Route path="/change-customer-information">
            {(customer.loggedIn)?
              <ChangeCustomerInformation divisions={divisions} />: 
              <Redirect to="/customer-login/change-customer-information" /> 
            }
          </Route>

          <Route path="/account-closure-security-refund">
            {(customer.loggedIn)?
              <AccountClosureSecurityRefund customer={customer} setCustomer={setCustomer} divisions={divisions}/>: 
              <Redirect to="/customer-login/account-closure-security-refund" /> 
            }
          </Route>

          <Route path="/temporary-disconnection-of-supply">
            {(customer.loggedIn)?
              <TemporaryDisconnectionSupply divisions={divisions} />: 
              <Redirect to="/customer-login/temporary-disconnection-of-supply" /> 
            }
          </Route>

          <Route path="/reconnection-power-supply">
            {(customer.loggedIn)?
              <ReconnectionPowerSupply divisions={divisions} />: 
              <Redirect to="/customer-login/reconnection-power-supply" /> 
            }
          </Route>

          <Route exact path="/ticket">
          {(customer.loggedIn)?
              <Ticket  />: 
              (props)=>
              <Redirect to={`/customer-login/ticket`} /> 
            }
          </Route>

          <Route exact path="/ticket/:ticketNo">
            {(customer.loggedIn)?
              <Ticket  />: 
              (props)=>
              <Redirect to={`/customer-login/ticket/${props.match.params.ticketNo}`} /> 
            }
        </Route>

        <Route exact path="/ticket/all">
            {(customer.loggedIn)?
              <Ticket  />: 
              (props)=>
              <Redirect to={`/customer-login/ticket/all`} /> 
            }
        </Route>

          <Route exact path="/customer-login/:url">
            <CustomerInputComponent customer={customer} setCustomer={setCustomer} dzongkhags={dzongkhags}/>
          </Route>
          <Route exact  path="/customer-login/:url/:ticketNo">
            <CustomerInputComponent customer={customer} setCustomer={setCustomer} dzongkhags={dzongkhags}/>
          </Route>
          <Route  exact path="/customer-login">
            <CustomerInputComponent customer={customer} setCustomer={setCustomer} dzongkhags={dzongkhags} />
          </Route>

          <Route  exact path="/customer-register">
            <CustomerRegisterComponent customer={customer} setCustomer={setCustomer} dzongkhags={dzongkhags} />
          </Route>

          <Route path="*">
            <ServiceMenuComponent />
          </Route>
        </Switch>
      </div>
    </Router>

   

    <footer className="bg-success1 text-center" id="bs-footer" >
      <Container className='pt-3 pt-lg-5 text-white h6'>
        <div className='mb-3'>
        For any enquiries, call<a href='tel:1250' className='text-decoration-none text-white pb-4'> 1250 </a> (toll free)
        </div>
        
        <div className='mb-3'>
        <a href='https://www.bpc.bt'  className='text-white pb-4 text-decoration-none'>Bhutan Power Corporation Limited </a> &copy; 2022 &nbsp;
        </div>
      
        <div className='mb-2 wangyel-studio-font'>
        <a href="https://fb.com/wangyel.studio/" className='text-light text-decoration-none'>Designed and Developed by Wangyel Studio</a>
        </div>
      </Container>
    </footer>
    <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
  </>
  );
}

export default App;
