import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, InputGroup, Badge, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { FileUploadField } from './fileUploadComponent';
import { URL } from '../common/constant';
import { CONSTRUCTION_TYPE } from '../common/constant';
import { Spinning } from './loading';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelectionForPowerClearance } from './categoryComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


const axios = require('axios').default;

export const PowerClearanceFormComponent = (props) =>{
    

    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: '',
        reason: '',
        thram:'',
        power_required:'',
        construction_type:'',
        terms:false,
        category:''
    });

    const history = useHistory();
    const [is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1, 'This field is required').required('This field is required'),
        reason: yup.string(),
        thram:yup.string().required('This field is required'),
        power_required: yup.number().min(1,'This field is required').required('This field is required'),
        construction_type: yup.string().required('Please select one'),
        category: yup.string().required('Please select one'),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted')
    });
    
    const [cidOwnerFile, setCidOwnerFile] = useState({file:'',error:null});
    const [clearanceLetterFile, setClearanceLetter] = useState({file:'',error:null});
    
	const handleFormSubmit =(values) =>{
        if(!cidOwnerFile.file)
            setCidOwnerFile({...cidOwnerFile, error:'Citizenship ID Card is required'})

        else if(!clearanceLetterFile.file)
            setClearanceLetter({...clearanceLetterFile, error:'Copy of Clearance Letter from Dzongkhag/Dungkhag/Geog is required'})
        
        else{
            setLoading(true);
            let formData = new FormData();
            formData.append('location', values.location);
            formData.append('reason', values.reason);
            formData.append('division', values.division);
            formData.append('thram', values.thram);
            formData.append('power_required', values.power_required);
            formData.append('construction_type', values.construction_type);
            formData.append('letter_of_clearance', clearanceLetterFile.file);
            formData.append('category', values.category);
            formData.append('cid_copy', cidOwnerFile.file);

            
            
            axios.post(`${URL}/services/power-clearances/`,formData,{
                headers: {
                    "Authorization": localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(function (response) {
                setLoading(false);
                setSubmitted(true);
                history.push(`/ticket/${response.data.id}?success`);
            })
            .catch(function (error) {
                setLoading(false);
                setShowModal(true);
                if(error.response){
                    setModalContent(JSON.stringify(error.response.data));
                }
                
                else
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            }); 
        }
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    useEffect(() => {
        console.log('Inside Power Clearance: ', props.divisions)
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/power-clearances/${params.get('service_id')}/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            
            setFormikValues(response.data)
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
        }
    },[]);


    return (
        <Container>
            <GoBackButton />
            <h3 className="text-center mt-2">
                {(params.get('ticket'))?<>Application ID <Badge bg="info" className="me-2">{params.get('ticket')} </Badge></>:null}
                Power clearance
            </h3>
            <i className='text-secondary'>
            <FontAwesomeIcon icon={faInfoCircle} className='me-2 text-secondary' />
            A power clearance ensures the availability of power. Any person/entity wishing to take up new construction activities requiring power supply should obtain power clearance from BPC.
            </i>
            

            <hr></hr>
            
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where clearance is required" >
                                <Form.Control type="text" 
                                    placeholder="Name of location" 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Reason" >
                                <Form.Control type="text" 
                                    placeholder="Reason" 
                                    name="reason"
                                    value={values.reason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.reason && !!errors.reason}
                                    isValid={ touched.reason && !errors.reason}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                            <FloatingLabel label="Type of Construction(Perm/Temp)" >
                                   
                                    <Form.Select 
                                        placeholder="Type of Construction(Perm/Temp)" 
                                        name="construction_type"
                                        value={values.construction_type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.construction_type && !!errors.construction_type}
                                        isValid={ touched.construction_type && !errors.construction_type}
                                        disabled={is_submitted}
                                    >
                                        <option value="">Choose One</option>
                                        {
                                            CONSTRUCTION_TYPE.map(ct=><option value={ct}>{ct}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.construction_type}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Thram/Plot No." >
                                <Form.Control type="text" 
                                    placeholder="Thram/Plot No." 
                                    name="thram"
                                    value={values.thram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.thram && !!errors.thram}
                                    isValid={ touched.thram && !errors.thram}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.thram}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            <InputGroup >
                                <FloatingLabel label="Demand Load" >
                                    <Form.Control type="number" 
                                        placeholder="Demand Load" 
                                        name="power_required"
                                        value={values.power_required}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.power_required && !!errors.power_required}
                                        isValid={ touched.power_required && !errors.power_required}
                                        disabled={is_submitted}
                                    />
                                </FloatingLabel>
                                <InputGroup.Text>kW</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">{errors.power_required}</Form.Control.Feedback>
                            </InputGroup>
                                
                            <Form.Text className="text-muted">
                                For power equal to 1 MW and above, your application will be processed by DHPS (Department of Hydro Power System), MOEA.
                            </Form.Text>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <CategorySelectionForPowerClearance 
                            values = {values}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                            label={'Category'}
                        />
                    </Row>

                    {
                        (params.get('service_id') !== null)?null:
                        <>
                            <Row>

                                <FileUploadField 
                                    name="cidOwner" 
                                    label="Copy of CID of the owner"
                                    file={cidOwnerFile}
                                    setFile = {setCidOwnerFile}
                                />
                                
                                <FileUploadField 
                                    name="letterFromDzongkhag" 
                                    label="Copy of letter / clearance from Dzongkhag / Dungkhag / Gewog / Thromde / MoEA"
                                    file={clearanceLetterFile}
                                    setFile = {setClearanceLetter}
                                />

                                <Form.Text className="text-muted">
                                    Copy of letter/clearance from Dzongkhag/Dungkhag/Gewog for Low Voltage Residential Customer in Rural Area
                                </Form.Text>

                                <Form.Text className="text-muted">
                                OR Copy of letter from Thromde along with the electrical load details for Low Voltage Residential Customer in Urban Area
                                </Form.Text>

                                <Form.Text className="text-muted">
                                OR Copy of letter from concerned authority / Department of Industries, MoEA for other customers in Rural/Urban
                                </Form.Text>
                            </Row>
                            
                           

                            <Row className="my-3">
                                <Form.Group as={Col} className="mb-2 ">
                                <Form.Check
                                    required
                                    name="terms"
                                    label="I assure that all the information filled in above are true and that I am liable for any
                                    action by the company if any of the information provided are incorrect."
                                    onChange={handleChange}
                                    isInvalid={!!errors.terms}
                                    feedback={errors.terms}
                                    feedbackType="invalid"
                                    id="terms Feedback"
                                    disabled={is_submitted}
                                    
                                    
                    
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Form.Group as={Col}   className="mt-2">
                                <Button type="submit" disabled={is_loading || is_submitted} >
                                    {is_loading ? <Spinning />: null}
                                    Submit 
                                </Button>
                            </Form.Group>
                    
                        </>
                    }

                </Form>
                )}
           </Formik>
            <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    )
}