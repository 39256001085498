import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, Badge, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { Spinning } from './loading';
import { FileUploadField } from './fileUploadComponent';
import { URL } from '../common/constant';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelectionForShifting } from './categoryComponent';
const axios = require('axios').default;

export const ShiftingElectricityInfraForm = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: 0,
        approval: '',
        thram:'',
        infrastructure:'',
        license:'',
        terms:false,
        yes_no:'',
        category: ''
    });
    const [hide_show_file, setHideShowFile] = useState();
    const history = useHistory();
    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1,'Division  is required').required('This field is required'),
        thram:yup.string(),
        infrastructure: yup.string().required('This field is required'),
        license: yup.string(),
        approval:yup.string(),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
        yes_no:yup.string().required('Please select one'),
        category: yup.string().required('Please select one'),
    });
    
    const [right_of_way_file, setRightOfWayFile] = useState({file:'',error:null});
    
    
	const handleFormSubmit =(values) =>{
        if(hide_show_file === 'd-block'){
            if(!right_of_way_file.file){
                setRightOfWayFile({...right_of_way_file, error:'Right of way clearance document is required'})
                return false;
            }
        }
        setLoading(true);
        let formData = new FormData();
        formData.append('location', values.location);
        formData.append('division', values.division);
        formData.append('thram', values.thram);
        formData.append('infrastructure', values.infrastructure);
        formData.append('license', values.license);
        formData.append('approval', values.approval);
        formData.append('right_of_way', right_of_way_file.file);
        formData.append('category', values.category);
        
        axios.post(`${URL}/services/shiftings/`,formData,{
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
        
    }

    const hideShowROWField = (status) =>{
        if (status){
            setHideShowFile('d-none');
        }
        else{
            setHideShowFile('d-block')
        }

    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    useEffect(() => {
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/shiftings/${params.get('service_id')}/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            setFormikValues(response.data)
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
        }
    },[]);
    
    return (
        <Container>
            
            <GoBackButton/>
            <h3 className="text-center mt-2">
                {(params.get('ticket'))?<>Application ID <Badge bg="info" className="me-2">{params.get('ticket')}</Badge></>:null}
                Shifting of electrical infrastructure
            </h3>
            <hr></hr>
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where shifting is required" >
                            <Form.Control 
                                placeholder="Name of location where shifting is required" 
                                name="location"
                                value={values.location}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.location && !!errors.location}
                                isValid={ touched.location && !errors.location}
                                disabled={is_submitted}
                            >   
                                
                            </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                                <Form.Select 
                                    placeholder="Division" 
                                    name="division"
                                    value={values.division}
                                    onChange ={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.division && !!errors.division}
                                    isValid={ touched.division && !errors.division}
                                    disabled={is_submitted}
                                >       
                                    <option value="0">Choose one / Reset</option>
                                    {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                                <FloatingLabel label="License No. (If applicable)" >
                                    
                                        <Form.Control 
                                            type="text"
                                            placeholder="License No. (If applicable)" 
                                            name="license"
                                            value={values.license}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={ touched.license && !!errors.license}
                                            isValid={ touched.license && !errors.license}
                                            disabled={is_submitted}

                                        />
                                        
                                        <Form.Control.Feedback type="invalid">{errors.license}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                                <FloatingLabel label="Approval No. (if applicable)" >
                                    <Form.Control type="text" 
                                        placeholder="Approval No. (if applicable)" 
                                        name="approval"
                                        value={values.approval}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.approval && !!errors.approval}
                                        isValid={ touched.approval && !errors.approval}
                                        disabled={is_submitted}

                                    />
                                    <Form.Control.Feedback type="invalid">{errors.approval}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>

                            
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Thram/Plot No. of the premise" >
                                <Form.Control type="text" 
                                    placeholder="Thram/Plot No." 
                                    name="thram"
                                    value={values.thram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.thram && !!errors.thram}
                                    isValid={ touched.thram && !errors.thram}
                                    disabled={is_submitted}

                                />
                                <Form.Control.Feedback type="invalid">{errors.thram}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            <FloatingLabel label="Infrastructure to be shifted" >
                                <Form.Control type="text" 
                                    placeholder="Power Required" 
                                    name="infrastructure"
                                    value={values.infrastructure}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.infrastructure && !!errors.infrastructure}
                                    isValid={ touched.infrastructure && !errors.infrastructure}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.infrastructure}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <CategorySelectionForShifting 
                            values = {values}
                            label = {'Category'}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                        />
                    </Row>
                    {
                        (params.get('service_id') !== null)?null:
                        <>
                        <Row className='ms-1 mb-2'>
                            Is the shifting within your premises?
                           
                            <Form.Check
                                inline
                                label="Yes"
                                name="yes_no"
                                type="radio"
                                id='yes'
                                onClick={()=>hideShowROWField(true)}
                                value='yes'
                                onChange={handleChange}
                                isInvalid={!!errors.yes_no}
                                feedback={errors.yes_no}
                                feedbackType="invalid"
                            />
                            <Form.Check
                                inline
                                label="No"
                                name="yes_no"
                                type="radio"
                                id='no'
                                onClick={()=>hideShowROWField(false)}
                                value='no'
                                onChange={handleChange}
                                isInvalid={!!errors.yes_no}
                                feedback={errors.yes_no}
                                feedbackType="invalid"
                            />
                             
                        </Row>

                        <Row className={hide_show_file}> 
                            <FileUploadField 
                                name="right_of_way_file" 
                                label="Right of Way Clearance Copy"
                                file={right_of_way_file}
                                setFile = {setRightOfWayFile}
                            />
                        </Row>
                    

                        <Row className="mb-3">
                            <Form.Group as={Col}  className="mb-2 ">
                            <Form.Check
                                required
                                name="terms"
                                label="I assure that all the information filled in above are true and that I am liable for any
                                action by the company if any of the information provided are incorrect."
                                onChange={handleChange}
                                isInvalid={!!errors.terms}
                                feedback={errors.terms}
                                feedbackType="invalid"
                                id="terms Feedback"
                                disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        </>
                    }

                    <Form.Group as={Col}   className="mt-2">
                        <Button type="submit" disabled={is_loading || is_submitted} hidden={(params.get('service_id') !== null)?true: false}>
                            {is_loading ? <Spinning />: null}
                            Submit 
                        </Button>
                    </Form.Group>
                </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    )
}