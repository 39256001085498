
import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, Badge, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {  useState, useEffect } from 'react';
import { Spinning } from './loading';
import { FileUploadField } from './fileUploadComponent';
import { URL } from '../common/constant';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelection } from './categoryComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const axios = require('axios').default;


export const ConstructionClearanceFormComponent = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: '',
        thram: '',
        reason: '',
        required_validity: '',
        license: '',
        approval: '',
        terms:false,
        category:''
    });
    const history = useHistory();

    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1,'Division  is required').required('This field is required'),
        thram:yup.string().required('This field is required'),
        reason: yup.string().required('This field is required'),
        category: yup.string().required('This field is required'),
        required_validity: yup.number().min(1,'Validity cannot be less than 1 day').max(365, 'Validity cannot be more than 365 days').required('This field is required (Please type only numbers)'),
        license: yup.string(),
        approval: yup.string(),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });
    
    const [licenseFile, setLicenseFile] = useState({file:'',error:null});
    const [workOrderFile, setWorkOrderFile] = useState({file:'',error:null});
    
	const handleFormSubmit =(values) =>{
        setLoading(true);
        let formData = new FormData();
        formData.append('location', values.location);
        formData.append('reason', values.reason);
        formData.append('division', values.division);
        formData.append('thram', values.thram);
        formData.append('required_validity', values.required_validity);
        formData.append('license', values.license);
        formData.append('approval', values.approval);
        formData.append('license_copy', licenseFile.file);
        formData.append('work_order_copy', workOrderFile.file);
        formData.append('category', values.category);
        
        
        axios.post(`${URL}/services/site-clearances/`,formData,{
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
        
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    useEffect(() => {
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/site-clearances/${params.get('service_id')}/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            setFormikValues(response.data)
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');

        });
        }
    },[]);


    return (
        <Container>
            <GoBackButton />
            <h3 className="text-center mt-2">
                {(params.get('ticket'))?<>Application ID <Badge bg="info" className="me-2">{params.get('ticket')} </Badge></>:null}
                Construction clearance</h3>
                <i className='text-secondary'>
                    <FontAwesomeIcon icon={faInfoCircle} className='me-2 text-secondary' />
                    A construction clearance will be required to ensure that the area is electrically safe before taking up any construction activities. Any person/entity taking up construction activities like digging or felling trees must obtain construction clearance from BPC prior to commencement of the activities.
                </i>
            <hr></hr>
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where clearance is required" >
                                <Form.Control type="text" 
                                    placeholder="Name of location" 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Thram/Plot No. of the premise" >
                                <Form.Control type="text" 
                                    placeholder="Thram/Plot No. of the premise" 
                                    name="thram"
                                    value={values.thram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.thram && !!errors.thram}
                                    isValid={ touched.thram && !errors.thram}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.thram}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                            <FloatingLabel label="Construction/Activity planned/Reason" >
                                <Form.Control 
                                    type="text"
                                    placeholder="Construction/Activity planned/Reason" 
                                    name="reason"
                                    value={values.reason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.reason && !!errors.reason}
                                    isValid={ touched.reason && !errors.reason}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-3 mb-md-0">
                            <FloatingLabel label="Clearance Validity Required(in days)" >
                                <Form.Control type="number" 
                                    placeholder="required_validity" 
                                    name="required_validity"
                                    value={values.required_validity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.required_validity && !!errors.required_validity}
                                    isValid={ touched.required_validity && !errors.required_validity}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.required_validity}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            <FloatingLabel label="License No." >
                                <Form.Control 
                                    type="text" 
                                    placeholder="License No." 
                                    name="license"
                                    value={values.license}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.license && !!errors.license}
                                    isValid={ touched.license && !errors.license}
                                    disabled={is_submitted}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">{errors.license}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            <FloatingLabel label="Work Order No." >
                                <Form.Control 
                                    type="text" 
                                    placeholder="work Order No ." 
                                    name="approval"
                                    value={values.approval}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.approval && !!errors.approval}
                                    isValid={ touched.approval && !errors.approval}
                                    disabled={is_submitted}
                                />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">{errors.approval}</Form.Control.Feedback>
                        </Form.Group>
                        <CategorySelection 
                            values = {values}
                            label = {'Area'}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                        />

                    </Row>
                    {
                        (params.get('service_id') !== null)?null:
                    
                    <>
                        <Row>
                            <FileUploadField 
                                name="licenseFile" 
                                label="Copy of License"
                                file={licenseFile}
                                setFile = {setLicenseFile}
                            />

                            <FileUploadField 
                                name="workOrderFile" 
                                label="Copy of Work Order"
                                file={workOrderFile}
                                setFile = {setWorkOrderFile}
                            />


                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}  className="mb-2 ">
                            <Form.Check
                                required
                                name="terms"
                                label="I assure that all the information filled in above are true and that I am liable for any
                                action by the company if any of the information provided are incorrect."
                                onChange={handleChange}
                                isInvalid={!!errors.terms}
                                feedback={errors.terms}
                                feedbackType="invalid"
                                id="terms Feedback"
                                disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                 
                    
                    <Form.Group as={Col}   className="mt-2">
                        <Button type="submit" disabled={is_loading || is_submitted} >
                            {is_loading ? <Spinning />: null}
                            Submit 
                        </Button>
                    </Form.Group>
                    </>
                }
            </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    )
}