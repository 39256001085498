import {Row, Col, Form, FloatingLabel, Button, Alert, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import { Spinning } from './loading';
import { useParams } from 'react-router';
import { Redirect } from 'react-router';
import { URL } from '../common/constant';
import { Link } from 'react-router-dom';
import { ErrorModal } from './successModal';
const axios = require('axios').default;


export const CustomerInputComponent = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const history = useHistory();
    const [userNotFoundAlertVisibility, setUserNotFoundAlertVisibility] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gewogs_main, setGewogsMain] = useState([]);
    const [gewogs, setGewogs] = useState([]);
    useEffect(() => {
        axios.get(`${URL}/addresses/gewogs/`)
        .then(function (response) {
            setGewogsMain(response.data);
            setGewogs(response.data);
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
    },[]);
    

    const schema = yup.object().shape({
        identification: yup.string().min(11).max(11).required('Citizenship Identification Number is a required field'),
        mobile: yup.number().min(10000000,'Minimum number is 10000000').max(79999999,'Maximum number 7999999').required(),
    });
    const identificationRef = useRef();
    const mobileRef = useRef();
    
    let { url } = useParams();
    let { ticketNo } = useParams();


    const handleCustomerReset =() =>{
        setUserNotFoundAlertVisibility(false);
        props.setCustomer({
            checkingIfCustomerIsRegistered: false,
            disabled: true,
            loggedIn:false,
            registeredCustomer:false,
            identification:'',
            mobile:'',
            name:'',
            email:'',
            fixed_line:'',
            village:'',
            gewog:'',
            dungkhag:'',
            dzongkhag:'',
            present_address:'',
            present_dzongkhag:''
        });
        history.push(`/home`);
    }

    if(props.customer.loggedIn){
        if(url && ticketNo){
            return( <Redirect to={`/${url}/${ticketNo}`} />  );
        }

        else if(url){
            return( <Redirect to={`/${url}`} />  );
        }
    }

    const handleRegisteredCustomerCHeck = (values) =>{
        setLoading(true);
        axios.post(`${URL}/accounts/login/`,{
            username: values.identification,
            password: values.mobile
            }
        )
        .then(function (response) {
            setUserNotFoundAlertVisibility(false); 
            localStorage.setItem('token', 'TOKEN '+response.data.token);

            const new_gewog = gewogs_main.find(gm => gm.name === response.data.permanent_address.gewog);
            const new_permanent_dzongkhag = props.dzongkhags.find(d => d.name === response.data.permanent_address.dzongkhag);
            const new_present_dzongkhag = props.dzongkhags.find(d => d.name === response.data.current_address.dzongkhag);
            
            props.setCustomer({
                ...props.customer, 
                identification:identificationRef.current.value, 
                mobile:mobileRef.current.value,
                checkingIfCustomerIsRegistered:false,
                loggedIn:true,
                disabled:true,
                registeredCustomer:true,
                name:response.data.name,
                email:response.data.email,
                village:response.data.permanent_address.village,
                gewog:new_gewog.id,
                dungkhag:'',
                fixed_line: response.data.fixed_line,
                dzongkhag:new_permanent_dzongkhag.id,
                present_address:response.data.current_address.address,
                present_dzongkhag:new_present_dzongkhag.id
            });
            history.push(`/`);
        })
        .catch(function (error) {
            
            setLoading(false);
            
            if(error.response){
                if(error.response.status=== 400){
                    
                    props.setCustomer({
                        ...props.customer, 
                        identification:identificationRef.current.value, 
                        mobile:mobileRef.current.value,
                        disabled: false,
                        checkingIfCustomerIsRegistered:false,
                    });
                    setModalContent("Account doesn't exists - Please ensure your CID/Mobile is correct");
                    setShowModal(true);
                }
                else{
                    setShowModal(true);
                    setModalContent(JSON.stringify(error.response.data));
                }
            }
            
            else{
                setLoading(false);
                props.setCustomer({
                    ...props.customer, 
                    identification:identificationRef.current.value, 
                    mobile:mobileRef.current.value,
                    checkingIfCustomerIsRegistered:false,
                });
                setShowModal(true);
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            }
        });
        
    }

  

    return (
        <Container >

            <Alert dismissible  variant='warning' className="my-2" show={(!props.customer.loggedIn && (url || ticketNo))?true: false}>
               {
                   (url === 'ticket')?
                   'You need to first sign-in or register before you can view your Application status/details':
                   'You need to first sign-in or register before you can submit your service request'
               } 
            </Alert>
            <h2 className="my-3 text-center"> </h2>
            
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleRegisteredCustomerCHeck(values)} 
                initialValues={props.customer} 
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className='justify-content-center'>
                        <Row className="mb-3 justify-content-center">
                            
                            <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                                <FloatingLabel label="Citizenship ID" >
                                    <Form.Control type="number" 
                                        size="sm"
                                        placeholder="Citizenship ID" 
                                        name="identification"
                                        value={values.identification}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.identification && !!errors.identification}
                                        isValid={ touched.identification && !errors.identification}
                                        ref={identificationRef}
                                        disabled={props.customer.loggedIn}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.identification}</Form.Control.Feedback>
                                </FloatingLabel>
                                
                            </Form.Group>
                        </Row>
                        <Row className='mb-3 justify-content-center'>

                            <Form.Group as={Col} md={6} >
                                <FloatingLabel label="Mobile" >
                                    <Form.Control type="number" 
                                        placeholder="phone number" 
                                        name="mobile"
                                        size="sm"
                                        value={values.mobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.mobile && !!errors.mobile}
                                        isValid={ touched.mobile && !errors.mobile}
                                        disabled={props.customer.loggedIn}
                                        ref = { mobileRef }
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className='justify-content-center mb-4'> 
                            {(!props.customer.loggedIn)?
                                <Form.Group as={Col} md={6}  className="mt-2">
                                    <Button type="submit" className="px-5 d-block w-100" size='lg'   disabled={loading}>
                                    {loading? <Spinning />: null}
                                    Sign in
                                    </Button>
                                    
                                </Form.Group>: 
                                <Form.Group as={Col}   className="mt-2">
                                    <Button type="button" onClick={handleCustomerReset} >
                                        Logout
                                    </Button>
                                </Form.Group>
                            }
                        </Row>
                        <hr></hr>
                        <Row className='mb-3'>
                        <i className="text-muted ms-2 text-center">Not Registered?</i> <Link to="/customer-register" className='d-inline-block text-center'>Register here</Link>
                            <Alert  variant='warning' className="my-2" show={userNotFoundAlertVisibility}>
                                Customer not found or your CID/mobile doesn't exists!
                            </Alert>
                        </Row>
                    </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    );
}